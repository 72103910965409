import React, {useState} from 'react';
import {Field, Form} from "react-final-form";
import {Button} from "../../components/Buttons";
import styled from "styled-components";
import {Input, Label, Select} from "../../components/Form";
import {ClientContactInfo} from "../../components/ClientContactInfo";
import {VehicleInfo} from "../../components/VehicleInfo";
import {ToDoInfo} from "../../components/ToDoInfo";
import axios from "axios";
import {apiUrl} from "../api";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {StyledTextarea} from "../../components/styled/inputs";
import arrayMutators from "final-form-arrays";
import {Files} from "../../components/Files";
import {createFormData} from "../../helpers/form";
import {File} from "../../components/File";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {Comment} from "../Comment/Comment";
import {Modal} from "../../components/Modal";
import {ModalButtonWrapper, P} from "../../components/Modals";

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`
const StyledForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 60px;
  margin-right: 60px;
`;

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 160px 240px;
  align-items: center;
  margin-bottom: 10px;
  justify-content: flex-start;
`;

const ClientInfoWrapper = styled.div`
  margin-left: 20px;
  margin-bottom: 10px;
  
  label {
    font-weight: bold;
    font-size: 11px;
  }
  
  textarea {
    margin-top: 2px;
  }
  
  a {
    cursor: pointer;
  }
`;

const StyledDivError = styled.div`
  color: red;
`;

export const InsurancePoliciesForm = ({
                                          id,
                                          initialValues,
                                          vehicle,
                                          client,
                                          toDo,
                                          companies,
                                          update,
                                          disabled,
                                          isUpdate
                                      }) => {
    const [signed, setSigned] = useState(null);
    const [paymentConfirmation, setPaymentConfirmation] = useState(null);
    const token = useSelector((store) => store.token);
    const [globalFiles, setGlobalFiles] = useState([]);
    const [globalPhotos, setGlobalPhotos] = useState([]);
    const [closeTaskModal, setCloseTaskModal] = useState(false);

    const setPhotosToForm = (photos, index) => {
        const obj = {
            index,
            photos
        }
        if (!globalPhotos) {
            return;
        }
        if (globalPhotos.some(e => e.index === index)) {
            let newArr = [...globalPhotos].map((item) => {
                if (item.index === index) {
                    return obj;
                }
                return item;
            });
            setGlobalPhotos(newArr);
        } else {
            setGlobalPhotos(prevState => [...prevState, obj]);
        }
    }

    const setFilesToForm = (files, index) => {
        const obj = {
            index,
            files
        }
        if (globalFiles.some(e => e.index === index)) {
            let newArr = [...globalFiles].map((item) => {
                if (item.index === index) {
                    return obj;
                }
                return item;
            });
            setGlobalFiles(newArr);
        } else {
            setGlobalFiles(prevState => [...prevState, obj]);
        }
    }


    const validate = (values) => {
        const errors = {}

        if (initialValues.type === 'gap') {
            return;
        }

        if (!values.tuIssuer) {
            errors.tuIssuer = 'Uzupełnij wystawcę polisy'
        }

        if (!values.number) {
            errors.number = 'Uzupełnij numer polisy'
        }
        if (!values.issuedAt) {
            errors.issuedAt = 'Uzupełnij datę wystawienia'
        }
        if (!values.insurancePremium) {
            errors.insurancePremium = 'Uzupełnij składkę finalną'
        }
        if (!values.startedAt) {
            errors.startedAt = 'Uzupełnij datę rozpoczęcia polisy'
        }
        if (!values.endedAt) {
            errors.endedAt = 'Uzupełnij datę zakończenia polisy'
        }
        if (!values.refinanced) {
            errors.refinanced = 'Wybierz składkę refinansowaną'
        }
        if (!values.paidAt) {
            errors.paidAt = 'Uzupełnij datę zapłaty'
        }
        if (!values.denunciation) {
            errors.denunciation = 'Wybierz wypowiedzenie'
        }
        return errors;
    }

    const closeManually = (data) => {
        axios.put(`${apiUrl}/policy/${id}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(() => {
            toast.success("Polisa została zamknięta.");
        }).catch((e) => {
            toast.error("Wystąpił błąd.");
        }).finally(() => {
            setCloseTaskModal(false);
        });
    }

    return (
        <>
            <Grid>
                <Form
                    initialValues={{...initialValues}}
                    validate={values => validate(values)}
                    onSubmit={values => {
                        const formData = new FormData();
                        Object.keys(values).forEach((key) => {
                            let value = values[key] ?? '';
                            if (value === true) {
                                value = 1;
                            } else if (value === false) {
                                value = 0;
                            }
                            formData.append(key, value)
                        });
                        if (signed) {
                            formData.append('signed', signed);
                        }
                        if (paymentConfirmation) {
                            formData.append('paymentConfirmation', paymentConfirmation);
                        }
                        createFormData(formData, 'photos', values.photos);
                        if (globalPhotos.length > 0) {
                            globalPhotos.forEach(({photos}) => {
                                photos.forEach((photo) => {
                                    formData.append(`photos[${photo.index}][file]`, photo.file);
                                })
                            });
                        }

                        createFormData(formData, 'files', values.files);
                        if (globalFiles.length > 0) {
                            globalFiles.forEach(({files}) => {
                                files.forEach((file) => {
                                    formData.append(`files[${file.index}][file]`, file.file);
                                })
                            });
                        }

                        if (initialValues.tu_fields) {
                            initialValues.tu_fields['insurance_constant_fields'] && Object.entries(initialValues.tu_fields['insurance_constant_fields']).forEach((element) => {
                                formData.append(`insuranceConstantFields[${element[0]}]`, element[1]);
                            });

                            initialValues.tu_fields['insurance_towing_fields'] && Object.entries(initialValues.tu_fields['insurance_towing_fields']).forEach((element) => {
                                formData.append(`insuranceTowingFields[${element[0]}]`, element[1]);
                            });
                        }


                        formData.append('_method', 'PUT')
                        axios.post(`${apiUrl}/policy/${id}`, formData, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            toast.success("Dane zostały zapisane.");
                            setSigned(null);
                            setPaymentConfirmation(null);
                            update();
                        }).catch(() => {
                            toast.error("Popraw dane");
                        });
                    }}
                    mutators={{
                        setValue: ([field, value], state, {changeValue}) => {
                            changeValue(state, field, () => value)
                        },
                        ...arrayMutators
                    }}
                    render={({handleSubmit, form, values}) => (
                        <StyledForm onSubmit={handleSubmit}>
                            <div>
                                {initialValues.type === 'policy' &&
                                    <fieldset disabled={disabled} style={{display: "contents"}}>
                                        <h2>Polisa</h2>
                                        <Field
                                            name='tuIssuer'
                                            parse={value => (value === "" ? null : value)}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputWrapper>
                                                        <Label htmlFor={input.name}>TU - Wystawca polisy*</Label>
                                                        <Select {...input} id={input.name}>
                                                            <option value="">TU - Wystawca polisy</option>
                                                            <option value="VAZUVI">VAZUVI</option>
                                                            <option value="Obce">Obce</option>
                                                        </Select>
                                                        {meta.error && meta.touched && <StyledDivError>{meta.error}</StyledDivError>}
                                                    </InputWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name='number'
                                            render={({input, meta}) => (
                                                <>
                                                    <InputWrapper>
                                                        <Label>Polisa nr*</Label>
                                                        <Input {...input} id={input.name} placeholder=""/>
                                                        {meta.error && meta.touched &&
                                                            <StyledDivError>{meta.error}</StyledDivError>}
                                                    </InputWrapper>
                                                </>
                                            )}
                                        />
                                        <Field
                                            name='issuedAt'
                                            type="date"
                                            parse={value => (value === "" ? null : value)}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputWrapper>
                                                        <Label>Data wystawienia*</Label>
                                                        <Input {...input} id={input.name} placeholder=""/>
                                                        {meta.error && meta.touched &&
                                                            <StyledDivError>{meta.error}</StyledDivError>}
                                                    </InputWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name='insurancePremium'
                                            render={({input, meta}) => (
                                                <>
                                                    <InputWrapper>
                                                        <Label>Składka finalna*</Label>
                                                        <Input {...input} id={input.name} placeholder=""/>
                                                        {meta.error && meta.touched &&
                                                            <StyledDivError>{meta.error}</StyledDivError>}
                                                    </InputWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name='startedAt'
                                            type="date"
                                            parse={value => (value === "" ? null : value)}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputWrapper>
                                                        <Label>Data rozpoczęcia polisy*</Label>
                                                        <Input {...input}
                                                               id={input.name}
                                                               placeholder=""
                                                               onChange={(e)=> {
                                                                   input.onChange(e);
                                                                   const endDate = new Date(e.target.value);
                                                                   endDate.setFullYear(endDate.getFullYear() + 1);
                                                                   endDate.setDate(endDate.getDate() - 1);
                                                                   const endDateString = endDate.getFullYear() + '-' + ("0" + (endDate.getMonth() + 1)).slice(-2) + '-' + ("0" + endDate.getDate()).slice(-2);
                                                                   form.mutators.setValue("endedAt", endDateString);
                                                               }}
                                                        />
                                                        {meta.error && meta.touched &&
                                                            <StyledDivError>{meta.error}</StyledDivError>}
                                                    </InputWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name='endedAt'
                                            type="date"
                                            parse={value => (value === "" ? null : value)}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputWrapper>
                                                        <Label>Data zakończenia polisy*</Label>
                                                        <Input {...input} id={input.name} placeholder=""/>
                                                        {meta.error && meta.touched &&
                                                            <StyledDivError>{meta.error}</StyledDivError>}
                                                    </InputWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name='refinanced'
                                            render={({input, meta}) => (
                                                <>
                                                    <InputWrapper>
                                                        <Label>Składka refinansowana*</Label>
                                                        <Select {...input} id={input.name}>
                                                            <option value="">Składka refinansowana</option>
                                                            <option value="Tak">Tak</option>
                                                            <option value="Nie">Nie</option>
                                                        </Select>
                                                        {meta.error && meta.touched &&
                                                            <StyledDivError>{meta.error}</StyledDivError>}
                                                    </InputWrapper>
                                                </>
                                            )}/>

                                        <Field
                                            name='paidAt'
                                            type="date"
                                            parse={value => (value === "" ? null : value)}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputWrapper>
                                                        <Label>Data zapłaty*</Label>
                                                        <Input {...input} id={input.name} placeholder=""/>
                                                        {meta.error && meta.touched &&
                                                            <StyledDivError>{meta.error}</StyledDivError>}
                                                    </InputWrapper>
                                                </>
                                            )}/>

                                        <Field
                                            name='denunciation'
                                            render={({input, meta}) => (
                                                <>
                                                    <InputWrapper>
                                                        <Label>Wypowiedzenie*</Label>
                                                        <Select {...input} id={input.name}>
                                                            <option value="">Wypowiedzenie</option>
                                                            <option value="Tak">Tak</option>
                                                            <option value="Nie">Nie</option>
                                                            <option value="Nie dotyczy">Nie dotyczy</option>
                                                        </Select>
                                                        {meta.error && meta.touched &&
                                                            <StyledDivError>{meta.error}</StyledDivError>}
                                                    </InputWrapper>
                                                </>
                                            )}/>

                                        <Field
                                            name='payment_deadline'
                                            type="date"
                                            parse={value => (value === "" ? null : value)}
                                            render={({input}) => (
                                                <InputWrapper>
                                                    <Label>Termin płatności</Label>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </InputWrapper>
                                            )}
                                        />
                                        <Field
                                            name='confirmation_leasing'
                                            type="date"
                                            parse={value => (value === "" ? null : value)}
                                            render={({input}) => (
                                                <InputWrapper>
                                                    <Label>Potwierdzenie do leasingu</Label>
                                                    <Input {...input} id={input.name} placeholder=""
                                                           onChange={(e) => {
                                                               input.onChange(e);
                                                               const tempDate = e.target.value;
                                                               const year = new Date(tempDate).getFullYear();
                                                               const month = new Date(tempDate).getMonth();
                                                               const day = new Date(tempDate).getDate();
                                                               const d = new Date(year + 1, month, day);
                                                               const datestring = d.getFullYear() + '-' + ("0" + (d.getMonth() + 1)).slice(-2) + '-' + ("0" + d.getDate()).slice(-2);
                                                               form.mutators.setValue("reminder", datestring)
                                                           }
                                                           }
                                                    />
                                                </InputWrapper>
                                            )}
                                        />
                                        <Field
                                            name='reminder'
                                            type="date"
                                            parse={value => (value === "" ? null : value)}
                                            render={({input}) => (
                                                <>
                                                    <InputWrapper>
                                                        <Label>Data przypomnienia</Label>
                                                        <Input {...input} id={input.name} placeholder=""/>
                                                    </InputWrapper>
                                                </>
                                            )}
                                        />

                                        <Field
                                            name='isSigned'
                                            type="checkbox"
                                            render={({input, meta}) => (
                                                <>
                                                    <InputWrapper>
                                                        <Label>Polisa podpisana</Label>
                                                        <Input {...input} id={input.name} placeholder=""/>
                                                    </InputWrapper>
                                                </>
                                            )}/>
                                    </fieldset>}
                                {initialValues.type === 'gap' &&
                                    <fieldset disabled={disabled} style={{display: "contents"}}>
                                        <h2>GAP</h2>
                                        <Field
                                            name='gap_tuIssuer'
                                            parse={value => (value === "" ? null : value)}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputWrapper>
                                                        <Label htmlFor={input.name}>TU - Wystawca polisy</Label>
                                                        <Select {...input} id={input.name}>
                                                            <option value="">TU - Wystawca polisy</option>
                                                            <option value="VAZUVI">VAZUVI</option>
                                                            <option value="Obce">Obce</option>
                                                        </Select>
                                                    </InputWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name={`gap_tu_id`}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputWrapper>
                                                        <label>TU</label>
                                                        <Select {...input} id={input.name} onChange={(e) => {
                                                            input.onChange(e);
                                                        }}>
                                                            <option value="">Firma</option>
                                                            {companies.map(({id, name}) => (
                                                                <option value={id}>
                                                                    {name}
                                                                </option>
                                                            ))}
                                                        </Select>
                                                    </InputWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name={`gap_financingPeriod`}
                                            type="number"
                                            parse={value => (value === "" ? null : value)}
                                            min={0}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputWrapper>
                                                        <label>Okres finasowania w latach</label>
                                                        <Input
                                                            {...input}
                                                            id={input.name}
                                                            placeholder=""
                                                            onChange={(e)=>{
                                                                input.onChange(e);
                                                                if (values.gap_startDate) {
                                                                    const financingPeriod = e.target.value ? parseInt(e.target.value) : 1;
                                                                    const endDate = new Date(values.gap_startDate);
                                                                    endDate.setFullYear(endDate.getFullYear() + financingPeriod);
                                                                    endDate.setDate(endDate.getDate() - 1);
                                                                    const endDateString = endDate.getFullYear() + '-' + ("0" + (endDate.getMonth() + 1)).slice(-2) + '-' + ("0" + endDate.getDate()).slice(-2);
                                                                    form.mutators.setValue("gap_endDate", endDateString);
                                                                }
                                                            }}
                                                        />
                                                    </InputWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name={`gap_sumInsured`}
                                            type="number"
                                            parse={value => (value === "" ? null : value)}
                                            min={0}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputWrapper>
                                                        <label>Suma ubezpieczenia</label>
                                                        <Input
                                                            {...input}
                                                            id={input.name}
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                input.onChange(e);
                                                            }}
                                                        />
                                                    </InputWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name={`gap_compensationLimit`}
                                            type="number"
                                            parse={value => (value === "" ? null : value)}
                                            min={0}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputWrapper>
                                                        <label>Limit odszkodowania</label>
                                                        <Input
                                                            {...input}
                                                            id={input.name}
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                input.onChange(e);
                                                            }}
                                                        />
                                                    </InputWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name={`gap_totalPremium`}
                                            type="number"
                                            parse={value => (value === "" ? null : value)}
                                            min={0}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputWrapper>
                                                        <label>Składka całkowita</label>
                                                        <Input
                                                            {...input}
                                                            id={input.name}
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                input.onChange(e);
                                                            }}
                                                        />
                                                    </InputWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name={`gap_number`}
                                            type="text"
                                            parse={value => (value === "" ? null : value)}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputWrapper>
                                                        <label>Polisa numer</label>
                                                        <Input
                                                            {...input}
                                                            id={input.name}
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                input.onChange(e);
                                                            }}
                                                        />
                                                    </InputWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name={`gap_type`}
                                            parse={value => (value === "" ? null : value)}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputWrapper>
                                                        <Label htmlFor={input.name}>Typ</Label>
                                                        <Select {...input} id={input.name}>
                                                            <option value="">Typ</option>
                                                            <option value="oneTime">Składka jednorazowa</option>
                                                            <option value="installment">Składka ratalna</option>
                                                        </Select>
                                                        {meta.error && meta.touched &&
                                                            <StyledDivError>{meta.error}</StyledDivError>}
                                                    </InputWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name={`gap_installmentValue`}
                                            type="number"
                                            parse={value => (value === "" ? null : value)}
                                            min={0}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputWrapper>
                                                        <label>Składka ratalna</label>
                                                        <Input
                                                            {...input}
                                                            id={input.name}
                                                            placeholder=""
                                                            disabled={(values.gap_type !== 'installment')}
                                                            onChange={(e) => {
                                                                input.onChange(e);
                                                            }}
                                                        />
                                                    </InputWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name={`gap_startDate`}
                                            type="date"
                                            parse={value => (value === "" ? null : value)}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputWrapper>
                                                        <label>Data rozpoczęcia</label>
                                                        <Input
                                                            {...input}
                                                            id={input.name}
                                                            placeholder=""
                                                            onChange={(e)=>{
                                                                input.onChange(e);
                                                                const financingPeriod = values.gap_financingPeriod ? parseInt(values.gap_financingPeriod) : 1;
                                                                const endDate = new Date(e.target.value);
                                                                endDate.setFullYear(endDate.getFullYear() + financingPeriod);
                                                                endDate.setDate(endDate.getDate() - 1);
                                                                const endDateString = endDate.getFullYear() + '-' + ("0" + (endDate.getMonth() + 1)).slice(-2) + '-' + ("0" + endDate.getDate()).slice(-2);
                                                                form.mutators.setValue("gap_endDate", endDateString);
                                                            }}
                                                        />
                                                    </InputWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name={`gap_endDate`}
                                            type="date"
                                            parse={value => (value === "" ? null : value)}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputWrapper>
                                                        <label>Data zakończenia</label>
                                                        <Input
                                                            {...input}
                                                            id={input.name}
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                input.onChange(e);
                                                            }}
                                                        />
                                                    </InputWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name={`gap_refinancingPremium`}
                                            parse={value => (value === "" ? null : value)}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputWrapper>
                                                        <Label htmlFor={input.name}>Składka refinansowania</Label>
                                                        <Select {...input} id={input.name}>
                                                            <option value="">Składka refinansowania</option>
                                                            <option value="inCarPrice">W cenie pojazdu</option>
                                                            <option value="inFee">Zawarta w prowizji</option>
                                                            <option value="externalInstallments">Raty zewnętrzne
                                                            </option>
                                                        </Select>
                                                        {meta.error && meta.touched &&
                                                            <StyledDivError>{meta.error}</StyledDivError>}
                                                    </InputWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name={`gap_dateOfPayment`}
                                            type="date"
                                            parse={value => (value === "" ? null : value)}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputWrapper>
                                                        <label>Termin płatności</label>
                                                        <Input
                                                            {...input}
                                                            id={input.name}
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                input.onChange(e);
                                                            }}
                                                        />
                                                    </InputWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name={`gap_reminder`}
                                            type="date"
                                            parse={value => (value === "" ? null : value)}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputWrapper>
                                                        <label>Przypomnienie</label>
                                                        <Input
                                                            {...input}
                                                            id={input.name}
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                input.onChange(e);
                                                            }}
                                                        />
                                                    </InputWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name={`gap_dateOfPayment2`}
                                            type="date"
                                            parse={value => (value === "" ? null : value)}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputWrapper>
                                                        <label>Data zapłaty</label>
                                                        <Input
                                                            {...input}
                                                            id={input.name}
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                input.onChange(e);
                                                            }}
                                                        />
                                                    </InputWrapper>
                                                </>
                                            )}/>
                                    </fieldset>}
                                <File
                                    name="paymentConfirmation_path"
                                    label="Potwierdzenie zapłaty składki/refinansowania*"
                                    onFileSelect={(file) => setPaymentConfirmation(file)}
                                    initialValue={values.paymentConfirmation}
                                />

                                <File
                                    name="signed_path"
                                    label="Podpisana polisa*"
                                    onFileSelect={(file) => setSigned(file)}
                                    initialValue={values.signed}
                                />

                                <Files name={`photos`} index={0} setFilesToForm={setPhotosToForm} label={`Zdjęcia`}/>

                                <Files name={`files`} index={0} setFilesToForm={setFilesToForm}/>

                                <Button>Zapisz</Button>
                            </div>
                            <div>
                                <fieldset disabled={disabled} style={{display: "contents"}}>
                                    {client && (
                                        <ClientInfoWrapper>
                                            <h4>Klient:</h4>
                                            <ClientContactInfo clientSimple={client}/>
                                        </ClientInfoWrapper>
                                    )}
                                    {vehicle && (
                                        <ClientInfoWrapper>
                                            <h4>Pojazd:</h4>
                                            <VehicleInfo vehicle={vehicle}/>
                                        </ClientInfoWrapper>
                                    )}
                                    {toDo && (
                                        <ClientInfoWrapper>
                                            <h4>Zadanie:</h4>
                                            <ToDoInfo toDo={toDo}/>
                                        </ClientInfoWrapper>
                                    )}
                                    {values['slackLink'] && (
                                        <ClientInfoWrapper>
                                            <a href={values['slackLink']} target="_blank">
                                                <FontAwesomeIcon icon={faArrowRight} color="black" fixedWidth />
                                                Kanał Slack
                                            </a>
                                        </ClientInfoWrapper>
                                    )}
                                    <Field
                                        name='note'
                                        render={({input}) => (
                                            <ClientInfoWrapper>
                                                <Label htmlFor={input.name}>Notatka:</Label>
                                                <StyledTextarea name="" id="" cols="30" rows="4" {...input}/>
                                            </ClientInfoWrapper>
                                        )}/>
                                </fieldset>
                                {!!id && <Form
                                    initialValues={initialValues}
                                    onSubmit={values => {
                                        const data = {
                                            closedManuallyAt: values.closedManuallyAt,
                                            isClosedManually: true,
                                            endedAt: values.closedManuallyAt
                                        };
                                        setCloseTaskModal(data);
                                    }}
                                    render={({handleSubmit}) => (
                                        <form onSubmit={handleSubmit}>
                                            <Field
                                                name="closedManuallyAt"
                                                type="date"
                                                parse={value => (value === "" ? null : value)}
                                                render={({input, meta}) => (
                                                    <>
                                                        <Input {...input} id={input.name} placeholder="" disabled={disabled}/>
                                                    </>
                                                )}/>
                                            {!disabled && <Button>Zakończenie polisy</Button>}
                                        </form>
                                    )}
                                />
                                }
                            </div>
                            {closeTaskModal && <Modal closeModal={() => {
                                setCloseTaskModal(false)
                            }}>
                                <P>Czy na pewno chcesz zakończyć polisę?</P>

                                <ModalButtonWrapper>
                                    <Button type="button" onClick={() => closeManually(closeTaskModal)}>Tak</Button>
                                    <Button type="button" inactive onClick={() => setCloseTaskModal(false)}>NIE</Button>
                                </ModalButtonWrapper>
                            </Modal>}
                        </StyledForm>
                    )}
                />
            </Grid>
            <Comment policyId={id} vehicleId={null} toDoId={null} toDoTab={null} archived={false} />
        </>
    );
};
